import { IconProps } from "../features/entities/general";

const WaitingMoney = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      className={className}
      onClick={onClick}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.51851V25.4815M11.2593 21.0283L12.6483 22.0697C14.4988 23.4588 17.4997 23.4588 19.3517 22.0697C21.2038 20.6807 21.2038 18.4304 19.3517 17.0414C18.4273 16.3461 17.2136 16 16 16C14.8543 16 13.7086 15.6523 12.8348 14.9586C11.087 13.5696 11.087 11.3193 12.8348 9.93027C14.5825 8.54123 17.4175 8.54123 19.1652 9.93027L19.821 10.4517M30.2222 16C30.2222 17.8677 29.8544 19.7171 29.1396 21.4426C28.4249 23.1681 27.3773 24.736 26.0566 26.0566C24.736 27.3773 23.1681 28.4249 21.4426 29.1396C19.7171 29.8543 17.8677 30.2222 16 30.2222C14.1323 30.2222 12.2829 29.8543 10.5574 29.1396C8.83187 28.4249 7.26403 27.3773 5.94337 26.0566C4.62272 24.736 3.57511 23.1681 2.86038 21.4426C2.14565 19.7171 1.77778 17.8677 1.77778 16C1.77778 12.228 3.27619 8.61055 5.94337 5.94336C8.61056 3.27618 12.228 1.77777 16 1.77777C19.772 1.77777 23.3894 3.27618 26.0566 5.94336C28.7238 8.61055 30.2222 12.228 30.2222 16Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 24C31 24.9193 30.8189 25.8295 30.4672 26.6788C30.1154 27.5281 29.5998 28.2998 28.9497 28.9498C28.2997 29.5998 27.5281 30.1154 26.6788 30.4672C25.8295 30.819 24.9193 31 24 31C23.0807 31 22.1705 30.819 21.3212 30.4672C20.4719 30.1154 19.7003 29.5998 19.0503 28.9498C18.4002 28.2998 17.8846 27.5281 17.5328 26.6788C17.1811 25.8295 17 24.9193 17 24C17 22.1435 17.7375 20.363 19.0503 19.0503C20.363 17.7375 22.1435 17 24 17C25.8565 17 27.637 17.7375 28.9497 19.0503C30.2625 20.363 31 22.1435 31 24Z"
        fill="white"
      />
      <path
        d="M27.0882 24.5405H23.5882V22.2071V19.8738M31 24C31 24.9193 30.8189 25.8295 30.4672 26.6788C30.1154 27.5281 29.5998 28.2998 28.9497 28.9498C28.2997 29.5998 27.5281 30.1154 26.6788 30.4672C25.8295 30.819 24.9193 31 24 31C23.0807 31 22.1705 30.819 21.3212 30.4672C20.4719 30.1154 19.7003 29.5998 19.0503 28.9498C18.4002 28.2998 17.8846 27.5281 17.5328 26.6788C17.1811 25.8295 17 24.9193 17 24C17 22.1435 17.7375 20.363 19.0503 19.0503C20.363 17.7375 22.1435 17 24 17C25.8565 17 27.637 17.7375 28.9497 19.0503C30.2625 20.363 31 22.1435 31 24Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WaitingMoney;

